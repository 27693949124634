import dummyImage from "assets/img/dummy.svg";
const campaignData = [
	{
		id: 0,
		name: "South Punjab Campaign",
		image: dummyImage,
		description: "Kaar-e-Kamal helped in Flood Campaign in different areas of Pakistan like Rajanpur, Fazilpur, Taunsa Sharif and many areas of Sindh in Tandoo M Khan."
		},
	{id: 1, name: "East Punjab Campagin", image: "", description: ""},
	{id: 2, name: "Syria Campaign", image: "", description: ""},
	{id: 3, name: "Palestine Campaign", image: "", description: ""},
];

export default campaignData;
