import lahoreImage from "assets/img/cities/1-lahore.png"
import rahimYarKhanImage from "assets/img/cities/2-rahim-yar-khan.jpg"
import multanImage from "assets/img/cities/3-multan.jpg"
import bahawalpurImage from "assets/img/cities/4-bahawalpur.jpg"
import gujranwalaImage from "assets/img/cities/5-gujranwala.png"
import islamabadImage from "assets/img/cities/6-islamabad.jpg"
import haroonabadImage from "assets/img/cities/7-haronabad.jpg"
import kamokeImage from "assets/img/cities/8-kamoke.jpg"
import chichawatniImage from "assets/img/cities/9-chichawatni.jpg"
import faislabadImage from "assets/img/cities/10-faislabad.jpg"
import bahawalnagarImage from "assets/img/cities/11-bahawalnagar.jpg"
import muridkeImage from "assets/img/cities/12-muridke.png"
import sheikhupuraImage from "assets/img/cities/13-sheikhupura.jpg"
import okaraImage from "assets/img/cities/14-okara.jpg"
import kharianImage from "assets/img/cities/15-kharian.jpg"
import mandiBahaUdDinImage from "assets/img/cities/16-mandi-baha-ud-din.jpg"
import sialkotImage from "assets/img/cities/17-sialkot.jpg"
import jhangImage from "assets/img/cities/18-jhang.jpg"
import kasurImage from "assets/img/cities/19-kasur.jpg"
import arifwalaImage from "assets/img/cities/20-arifwala.jpg"
import bhakkarImage from "assets/img/cities/21-bhakkar.jpg"
import mianwaliImage from "assets/img/cities/22-mianwali.jpg"
import vehariImage from "assets/img/cities/23-vehari.jpg"
import mamukanjanImage from "assets/img/cities/24-mamukanjan.webp"
import khanewalImage from "assets/img/cities/25-khanewal.jpg"
import quettaImage from "assets/img/cities/26-quetta.png"
import sahiwalImage from "assets/img/cities/27-sahiwal.webp"
import chakwalImage from "assets/img/cities/28-chakwal.jpg"
import morekhundaImage from "assets/img/cities/29-morekhunda.webp"
import layyahImage from "assets/img/cities/30-layyah.png"
import narowalImage from "assets/img/cities/31-narowal.jpg"
import abbotabadImage from "assets/img/cities/32-abbotabad.jpg"


const citiesData = [
	{
		id: "LHR",
		name: "Lahore",
		image: lahoreImage,
		core: [
			{role: "President", name: "Rana Bilal Yousaf"},
			{role: "Vice President", name: "Ahmed Aamir"},
			{role: "General Secretary", name: "Usama Rafiq"},
			{role: "Director General", name: "Tahir Zahid Rajpoot"},
			{role: "Treasurer", name: "Aqib Jawad"},
			{role: "Female Team Lead", name: "Syeda Afnan"},
			{role: "Director Media", name: "Zaeema Anwar"},
			{role: "Director PR", name: "Uzair Amir"},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: "Ammaz"},
			{role: "Director Blood Donations", name: "Ammar Haseeb"},
			{role: "Director Documentation", name: "Eman Fatima"},
			{role: "Director Social Media", name: "Fatima"},
			{role: "Director Inductions", name: "Kainat Nisar"},
			{role: "Director Operations", name: ""},
		],
	},

	{
		id: "RYK",
		name: "Rahim Yar Khan",
		image: rahimYarKhanImage,
		core: [
			{role: "President", name: "Sannan Arshad"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: "Abubakar"},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},
	{
		id: "GRW",
		name: "Gujranwala",
		image: gujranwalaImage,
		core: [
			{role: "President", name: "Muneeb Tariq"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: "Komal Yasmeen"},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "BWP",
		name: "Bahawalpur",
		image: bahawalpurImage,
		core: [
			{role: "President", name: "Abdullah Saleem"},
			{role: "Vice President", name: "Arham Zafar"},
			{role: "General Secretary", name: "Asad Jameel"},
			{role: "Director Operations", name: "Saif Ur Rehman"},
			{role: "Female Team Lead", name: "Saman Mehak"},
			{role: "Director General", name: "Huzaifa Iftikhar"},
			{role: "Treasurer", name: "Laiba Nadeem"},
			{role: "Director Media", name: "Bakhtawar Shuja"},
			{role: "Director PR", name: "Shahina Abbasi"},
			{role: "Director Events", name: "Usama Safdar"},
			{role: "Director Sponsorship", name: "Sohail Ashraf"},
			{role: "Director Blood Donations", name: "Waqar Ahmed"},
			{role: "Director Documentation", name: "Fatimah Munir"},
			{role: "Director Social Media", name: "Syed Abdullah"},
			{role: "Director Inductions", name: "Shahreen Malik"},
		],
	},

	{
		id: "ISB",
		name: "Islamabad",
		image: islamabadImage,
		core: [
			{role: "President", name: "M. Daniyal Shahid"},
			{role: "Vice President", name: "Zoya Arooj"},
			{role: "General Secretary", name: "M. Talha ilyas"},
			{role: "Director Operations", name: "Muhammad Faisal"},
			{role: "Female Team Lead", name: "Shehla Saghir"},
			{role: "Director General", name: "Ali Affaq"},
			{role: "Treasurer", name: "Kashaf toheed"},
			{role: "Director Media", name: "Uzair Kabeer"},
			{role: "Director PR", name: "Fahad Satti"},
			{role: "Director Events", name: "Jawad Ashraf"},
			{role: "Director Sponsorship", name: "M. Amanullah"},
			{role: "Director Blood Donations", name: "Hammad Ali"},
			{role: "Director Documentation", name: "Abdul Rahman"},
			{role: "Director Social Media", name: "Ammar Mukhtar "},
			{role: "Director Inductions", name: "Kashaf Nazir"},
		],
	},

	{
		id: "MUL",
		name: "Multan",
		image: multanImage,
		core: [
			{role: "President", name: "Qamar Mohyouddin"},
			{role: "Vice president", name: "Saweera Akram"},
			{role: "General secretary", name: "Qamar Mohyouddin"},
			{role: "Female team lead", name: "arooj fatima"},
			{role: "Director of operations", name: "Iram shahid butt"},
			{role: "Director finance", name: "Irfa choudhary"},
			{role: "Director social media", name: "Engr Shahid Bhutta"},
			{role: "Director General", name: "Saif ur rehman"},
			{role: "Director PR", name: "novera Ismail"},
			{role: "Director Media", name: "Shahid khan minzai"},
			{role: "Director Inductions", name: "Talha latif"},
			{role: "Director Events", name: "saaif ul Rehman"},
			{role: "Director Sponsorship", name: "Usman khan"},
			{role: "Director Documentation", name: "Muhammad Tufail"},
			{role: "Director BDS", name: "Moeen saqi"},
		],
	},

	{
		id: "CWN",
		name: "Chichawatni",
		image: chichawatniImage,
		core: [
			{role: "President", name: "Hanzla Naveed"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "HND",
		name: "Haroonabad",
		image: haroonabadImage,
		core: [
			{role: "President", name: "Arslan Maqbool"},
			{role: "Vice President", name: "Numman Sharif"},
			{role: "General Secretary", name: "Hafiz Muzammil"},
			{role: "Director Operations", name: "Muhammad Abdullah Ch"},
			{role: "Female Team Lead", name: "Hajra Sarwar"},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: "Talha Mukhtar"},
			{role: "Director Media", name: "Hamza Naveed"},
			{role: "Director PR", name: "Ahad Israa"},
			{role: "Director Events", name: "Zain Ali Sakhi"},
			{role: "Director Sponsorship", name: "Usama Ayub"},
			{role: "Director Blood Donations", name: "Abid Hussain"},
			{role: "Director Documentation", name: "Iqra Khalid"},
			{role: "Director Social Media", name: "Faizan Khalid"},
			{role: "Director Inductions", name: "Zafar Sukhera"},
		],
	},

	{
		id: "FSB",
		name: "Faislabad",
		image: faislabadImage,
		core: [
			{role: "President", name: "M Muzammil"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: " "},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "BWN",
		name: "Bhawalnagar",
		image: bahawalnagarImage,
		core: [
			{role: "President", name: "Khizar Ashfaq"},
			{role: "Vice President", name: "Bilal Riaz"},
			{role: "General Secretary", name: "Juniad Akhtar"},
			{role: "Director Operations", name: "Hashir Ali"},
			{role: "Female Team Lead", name: "Guriya Jutt"},
			{role: "Director General", name: "Khubaib Ahmad"},
			{role: "Treasurer", name: "Ibrar Ahmad "},
			{role: "Director Media", name: "Hamza Malik"},
			{role: "Director PR", name: "Abubakar Moiz"},
			{role: "Director Events", name: "ihtisham jutt"},
			{role: "Director Sponsorship", name: "Haseeb Malik"},
			{role: "Director Blood Donations", name: "Talha Razzaq"},
			{role: "Director Documentation", name: "Ayesha Tariq "},
			{role: "Director Social Media", name: "Wania Riaz"},
			{role: "Director Inductions", name: "Zarish"},
		],
	},

	{
		id: "SKL",
		name: "Shiekhpura",
		image: sheikhupuraImage,
		core: [
			{role: "President", name: "Ali"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "OKR",
		name: "Okara",
		image: okaraImage,
		core: [
			{role: "President", name: "Maratab"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MRK",
		name: "Muridke",
		image: muridkeImage,
		core: [
			{role: "President", name: "Khizar Sardar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "KSR",
		name: "Kasur",
		image: kasurImage,
		core: [
			{role: "President", name: "M Ahmed Shafique"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "SKT",
		name: "Sialkot",
		image: sialkotImage,
		core: [
			{role: "President", name: "Ezza Sikandar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MBN",
		name: "Mandi Bahaudin",
		image: mandiBahaUdDinImage,
		core: [
			{role: "President", name: "Waleed Zafar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "KHR",
		name: "Kharian",
		image: kharianImage,
		core: [
			{role: "President", name: "Umer"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "JHN",
		name: "Jhang",
		image: jhangImage,
		core: [
			{role: "President", name: "Idrees Ajmal"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "ARF",
		name: "Arif Wala",
		image: arifwalaImage,
		core: [
			{role: "President", name: "Meeral Maqbool"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "BKR",
		name: "Bhakkar",
		image: bhakkarImage,
		core: [
			{role: "President", name: "Khansa Urooj"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MKJN",
		name: "Mamukanjan",
		image: mamukanjanImage,
		core: [
			{role: "President", name: "M Amir Sajjad"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: " "},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MWN",
		name: "Mianwali",
		image: mianwaliImage,
		core: [
			{role: "President", name: "Rizwan Ahmed"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: " "},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "VEH",
		name: "Vehari",
		image: vehariImage,
		core: [
			{role: "President", name: "Abdullah Dildar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: " "},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "KHN",
		name: "Khanewal",
		image: khanewalImage,
		core: [
			{role: "President", name: "Maarij Ali"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: " "},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "KMK",
		name: "Kamoke",
		image: kamokeImage,
		core: [
			{role: "President", name: "Mubara Sajjad"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "QTA",
		name: "Quetta",
		image: quettaImage,
		core: [
			{role: "President", name: "Malik Moiz Asghar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "SWL",
		name: "Sahiwal",
		image: sahiwalImage,
		core: [
			{role: "President", name: ""},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "CKW",
		name: "Chakwal",
		image: chakwalImage,
		core: [
			{role: "President", name: ""},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MKD",
		name: "More Khunda",
		image: morekhundaImage,
		core: [
			{role: "President", name: ""},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "LAY",
		name: "Layyah",
		image: layyahImage,
		core: [
			{role: "President", name: ""},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "NWL",
		name: "Narowal",
		image: narowalImage,
		core: [
			{role: "President", name: ""},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "ABT",
		name: "Abbotabad",
		image: abbotabadImage,
		core: [
			{role: "President", name: ""},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	

];

export default citiesData;
