import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Card from "components/cards/Card";
import React from "react";
import {Link} from "react-router-dom";
import contactData from "utils/data/contact";
import mainAccounts from "utils/data/mainAccounts";

function Contact() {
	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
	};
	return (
		<main className='h-full pt-36 px-8 md:px-24 py-12'>
			<h1 className='text-theme-gray/90 text-3xl md:text-6xl font-semibold mb-10'>Contact Us</h1>
			<div className='flex flex-wrap h-full items-center'>
				{contactData.map((contact) => (
					<a href={contact.link}>
						<Card className='p-6 min-w-[14rem]' key={contact.id}>
							<h3 className='text-xl font-semibold text-theme-gray/30 py-3'>{contact.platform}</h3>
							{/* <span className='text-theme-gray/70 flex items-center justify-between my-3 w-full'>
								<p>{contact.platform}</p>
							</span> */}
							<p className='text-theme-gray/70 flex items-center justify-between mb-3 w-full'>{contact.username}</p>
						</Card>
					</a>
				))}
				<div className='radial-gradient-left'></div>
			</div>
		</main>
	);
}

export default Contact;
