import React from "react";

function Card({children, className}) {
	return (
		<div className='card-gradient p-[1px] rounded-3xl hover:shadow-2xl cursor-pointer m-4  backdrop-blur-md '>
			<div className={`rounded-3xl flex flex-col p-3 bg-white ${className}`}>{children}</div>
		</div>
	);
}

export default Card;
