import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import citiesData from "utils/data/cities";

function City() {
	const {cityId} = useParams();
	const [city, setCity] = useState({});
	useEffect(() => {
		const tCity = citiesData.find((c) => c.id === cityId);
		setCity(tCity);
	}, [cityId]);

	return (
		<div className='flex flex-col pt-24 px-24'>
			<div className='w-full '>
				<h1 className='text-6xl font-bold text-theme-gray text-center mb-7 my-4'>{city.name}</h1>
				{/* <section className='bg-theme-gray flex justify-between items-center py-20  radial-gradient-start'>
					<div className='text-white w-2/5 pl-32 leading-7'>
						<h1 className='text-4xl font-semibold mb-10'>President's Message</h1>
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
						since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
						five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
						release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
						including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
						industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
						specimen book.
					</div>
					<div className='radial-gradient-left'></div>
					<div className='radial-gradient-end w-1/2 flex items-end justify-center'>
						<img src={Founder} alt='Founder' />
					</div> 
				</section> */}
				<h1 className='our-executives text-5xl h-24 w-full flex items-center justify-center my-28 mb-10'>Our Executives</h1>
				<div className='flex flex-wrap justify-center'>
					{city?.core?.map((core) => (
						<div className='card-gradient p-[1px] m-3 rounded-xl'>
							<div className='p-8 px-8 w-full h-full shadow-md bg-white  rounded-xl hover:bg-theme-mustard cursor-pointer group'>
								<p className='text-theme-gray/[3%] stroke-2 stroke-theme-mustard text-6xl font-bold'>
									{core?.role
										?.split(" ")
										.map((s) => s[0])
										.join("")}
								</p>
								<p className='text-theme-gray/70 group-hover:text-white my-1'>{core.role}</p>
								<h2 className='text-2xl font-semibold text-theme-gray/40 group-hover:text-white'>{core.name}</h2>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* <img src={city.image} alt={city.name} className='w-1/2 h-96 object-cover rounded-3xl' /> */}
		</div>
	);
}

export default City;
