import React from "react";
import logoBlack from "assets/img/logo.png";

function Footer() {
	return (
		<footer className='h-64 bg-gray-500/20 backdrop-blur-md px-24 py-16 flex justify-between text-theme-gray/80'>
				<div className='flex flex-col items-start'>
					<img src={logoBlack} alt='Kaar-e-Kamal' />
					<p className='text-sm mt-5'>Our vision is to feed everyone, no one will sleep without having a portion of food.</p>
				</div>
				{/* <div className='grid grid-cols-3 grid-flow-row w-1/2'>
					<div>
						<h3 className='font-semibold mb-4'>About</h3>
						<p>About Us</p>
						<p>Events and Campaigns</p>
					</div>
					<div>
						<h3 className='font-semibold mb-4'>Domains</h3>
						<p>Rashan</p>
						<p>School Fee</p>
						<p>Medicine</p>
					</div>
					<div>
						<h3 className='font-semibold mb-4'>Movement</h3>
						<p>What are we?</p>
						<p>Join Us</p>
						<p>Donate to our cause</p>
					</div>
				</div> */}
		</footer>
	);
}

export default Footer;
