import React, { useEffect, useState } from "react";
import FigureSection from "components/FigureSection";
import Founder from "assets/img/bilal-yousaf.png";
import OutlinedButton from "components/buttons/OutlinedButton";
import { fetchData } from "requests/Requests";
import citiesData from "utils/data/cities";
import CityCard from "components/cards/ImageCard";
import domainData from "utils/data/domains";
import ImageCard from "components/cards/ImageCard";
import DividedSection from "components/DividedSection";
import campaignData from "utils/data/campaigns";
import GlassButton from "components/buttons/GlassButton";
import { Link, useNavigate } from "react-router-dom";
import PATH from "utils/constants/PathConstant";
import { INDUCTION_LINK } from "utils/constants/global";

function Home() {
  // const [cities, setCities] = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  // 	const getCities = async () => {
  // 		const citiesRes = await fetchData("cities");
  // 		setCities(citiesRes);
  // 	};
  // 	getCities();
  // }, []);

  return (
    <div>
      <section className="flex flex-col items-start justify-center text-white hero h-screen px-8 md:px-24">
        <div className="flex flex-col items-start justify-around md:w-2/5 h-1/2">
          <h1 className="text-6xl mb-3 md:text-8xl font-extrabold">
            Donate for <h1 className="text-theme-mustard">AfterLife</h1>
          </h1>
          <p className="text-xl">
            Kaar e Kamal is a registered non profit organisation started 5 years
            back with one 100 rupees. Currently, the team has been extended to
            50 cities of Pakistan. Kamalians force is the ray of hope for
            deprived families and is helping 4000+ families on a monthly basis.
          </p>
          <div className="flex mt-10">
            <OutlinedButton onClick={() => navigate(PATH.DONATE)}>
              BECOME A DONOR
            </OutlinedButton>
          </div>
        </div>
      </section>
      <main className="md:px-24">
        <section className="flex flex-col md:flex-row items-center justify-around w-full my-20">
          <FigureSection figure="4K" label="Families Helped Monthly" />
          <FigureSection figure="50" label="Cities we are in" />
          <FigureSection figure="6K" label="Total Team Members" />
        </section>
        <section className="bg-white/10 backdrop-blur-lg flex flex-col md:flex-row justify-between md:items-center py-8 md:py-20 radial-gradient-start">
          <div className="text-white md:w-2/5 px-8 md:px-auto md:pl-32 leading-7">
            <h1 className="text-4xl font-semibold mb-10">Founder's Message</h1>
            Kaar e Kamal was started just because the pain of a helpless person
            who needed someone to listen to him. So, my message to every reader
            here is to listen to the story of one family, student or any needy
            person who needs some help. Even with lesser financial resources you
            can use your contacts to bring ease in someone's life with a very
            small effort. All of us will get what's written in our destiny but
            in the race of fulfilling our dreams we should never ignore those
            around us whose basic dream is to fulfill their basic needs of life.
            With a chain of 100 Rs a month, everyone can help a family making
            sure we won't hurt their privacy. Let's leave this world with a
            better mark and an asset that created ease for others. Help others
            to help yourself and create a better world that will help a widow to
            feed her children, that will bring smile on the face of father when
            his children will get into school or bring relief for the sick when
            he is helpless. All of us will have to go through some test in this
            world and helping His creature will surely help you someday to get
            through your exam.
          </div>
          <div className="radial-gradient-left"></div>
          <div className="radial-gradient-end w-1/2 flex items-end justify-end self-end md:justify-center">
            <img src={Founder} alt="Founder" />
          </div>
        </section>

        <section className="flex flex-col items-center">
          <h1 className="our-chapters text-5xl h-24 w-full flex items-center justify-center my-20 mb-10">
            Our Chapters
          </h1>
          <div className="w-full overflow-x-scroll rounded-2xl">
            <div className="flex w-max my-10">
              {citiesData.map((city) => (
                <Link skey={city.id} to={`${PATH.CITIES}/${city.id}`}>
                  <CityCard name={city.name} image={city.image} />
                </Link>
              ))}
            </div>
          </div>
        </section>
        <section className="carousel">
          {campaignData.map((campaign) => (
            <DividedSection
              key={campaign.id}
              heading={campaign.name}
              image={campaign.image}
              text={campaign.description}
              className="carousel-item"
            />
          ))}
        </section>
        <section className="flex flex-col items-center" id="domains">
          <h1 className="our-domains text-5xl h-24 w-full flex items-center justify-center my-20 mb-10">
            Our Domains
          </h1>
          <div className="flex w-full flex-wrap items-center justify-center">
            {domainData.map((domain) => (
              <ImageCard
                key={domain.id}
                id={domain.id}
                image={domain.image}
                name={domain.name}
              />
            ))}
          </div>
        </section>
        <section className="bg-gradient-to-br from-theme-gray to to-theme-mustard rounded-2xl p-10 md:py-32 my-24 w-full text-center flex flex-col items-center">
          {/* <div className='radial-gradient-left'></div> */}
          <h2 className="text-white text-3xl md:text-5xl mb-8">
            Join Hands With Us
          </h2>
          <p className="text-white/80">
            Join hands with Kaar e Kamal , if you want to join us, fill the form
            given below.
          </p>
          <a href={INDUCTION_LINK}>
            <GlassButton className="mt-16 text-lg shadow-xl text-white">
              Join Us &nbsp; &#10141;
            </GlassButton>
          </a>
        </section>
      </main>
    </div>
  );
}

export default Home;
