const REQUEST_URL = `${process.env.REACT_APP_BACKEND_URL}/api`;
const TOKEN = `${process.env.REACT_APP_API_ACCESS_TOKEN}`;
const API_OPTIONS = {
  Authorization: `Bearer ${TOKEN}`,
  "Content-Type": "application/json",
};

const fetchData = async (collection) => {
  const res = await fetch(`${REQUEST_URL}/${collection}`, API_OPTIONS);
  return res;
};

export { fetchData };
