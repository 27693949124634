import React from "react";

function GlassButton({children, className}) {
	return (
		<button
			className={`backdrop-blur-md bg-theme-mustard/10 border-theme-mustard/40 px-3 md:px-7 text-sm md:text-md py-2 rounded-md border-[1px] text-theme-mustard ${className}`}
		>
			{children}
		</button>
	);
}

export default GlassButton;
