import rationImg from 'assets/img/ration.jpeg'
import schoolFeeImg from 'assets/img/school-fee.jpeg';
import bdsImg from 'assets/img/bds.jpeg'
import rozgarImg from 'assets/img/rozgar.jpeg'
import medicineImg from 'assets/img/medicine.jpeg'

const domainData = [
	{id: "RAS", name: "Rashan", image: rationImg},
	{id: "FEE", name: "School Fee", image: schoolFeeImg},
	{id: "BDS", name: "Blood Donation", image: bdsImg},
	{id: "ROZ", name: "Rozgar", image: rozgarImg},
	{id: "MED", name: "Medicine", image: medicineImg},
];
export default domainData;
