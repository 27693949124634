import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Card from "components/cards/Card";
import React from "react";
import mainAccounts from "utils/data/mainAccounts";

function Donate() {
	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
	};
	return (
		<main className='h-full pt-36 px-8 md:px-24 py-12'>
			<h1 className='text-theme-gray/90 text-3xl md:text-6xl font-semibold'>Donate to our cause</h1>
			<div className='flex flex-wrap h-full items-center'>
				{mainAccounts.map((account) => (
					<Card className='p-6' key={account.id}>
						<h3 className='text-2xl font-semibold text-theme-gray/50 py-3'>{account.provider}</h3>
						<span className='text-theme-gray/70 flex items-center justify-between my-3 w-full'>
							<p>Account Number:</p> <b className='ml-2'>{account.number}</b>
						</span>
						<p className='text-theme-gray/70 flex items-center justify-between mb-3 w-full'>
							Account Holder's Name: <b className='ml-2'>{account.name}</b>
						</p>

						<span className='flex items-center justify-end mt-4'>
							<FontAwesomeIcon className=' opacity-25 hover:opacity-60' icon={faCopy} onClick={() => copyToClipboard(account.number)} />
						</span>
					</Card>
				))}
				<div className='radial-gradient-left'></div>
			</div>
		</main>
	);
}

export default Donate;
