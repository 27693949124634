import React from "react";
import Footer from "./Footer";
import Header from "./Header";

function Layout({ children }) {
  return (
    <div className="min-h-[100vh]">
      <Header />
      <div className="min-h-[calc(100vh-16rem)]">{children}</div>
      <Footer />
    </div>
  );
}

export default Layout;
