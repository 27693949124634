import React from "react";

function FigureSection({ figure, label }) {
  return (
    <div className="flex flex-col items-center my-3 md:my-auto">
      <h1 className="text-7xl font-semibold">
        {figure}
        <span className="text-theme-mustard">+</span>
      </h1>
      <p className="text-theme-gray/50 text-2xl w-4/5 text-center">{label}</p>
    </div>
  );
}

export default FigureSection;
