import React from "react";
import logo from "assets/img/logo.png";
import {Link, NavLink} from "react-router-dom";
import NAV_LIST from "utils/constants/NavConstants";
import GlassButton from "components/buttons/GlassButton";
import ThemeSwitch from "components/switch/ThemeSwitch";
import PATH from "utils/constants/PathConstant";

function Header() {
	return (
		<div className='flex w-screen h-16 items-center justify-between lg:px-24 fixed top-0 z-10 text-white bg-theme-gray/80 backdrop-blur-lg'>
			<a href={PATH.HOME}>
				<img src={logo} alt='logo' className='p-2 h-12 lg:h-16' />
			</a>
			<nav className='w-2/4 flex items-center justify-between text-sm md:text-md'>
				{NAV_LIST.map((nav) => (
					<NavLink to={nav.href} className={({isActive}) => (isActive ? "border-b-2 opacity-100" : "opacity-50 hover:opacity-80")}>
						{nav.text}
					</NavLink>
				))}
			</nav>
			<div className='flex items-center justify-between pr-3'>
				<Link to={PATH.DONATE}>
					<GlassButton>Donate</GlassButton>
				</Link>
				{/* <ThemeSwitch /> */}
			</div>
		</div>
	);
}

export default Header;
